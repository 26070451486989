
import React from 'react'
import './Signature.css'

function Signature() {
    return (
        <div>
            <h5>© Alexander Carr 2021</h5>
        </div>
    );
}

export default Signature