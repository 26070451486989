
const myExperience = [
    {   title: 'Controls Engineer',
        company: 'Douglas Machine',
        description: '',
        dates: '',
        type: ''
    },
    {   title: 'Field Service Tech.',
        company: 'Douglas Machine',
        description: '',
        dates: '',
        type: ''
    },
    {   title: 'Software Developer Fellow',
        company:  'General Assembly',
        description: '',
        dates: '',
        type: ''
    }//,
//     {   title: '',
//         company:  '',
//         description: '',
//         dates: '',
//         type: ''
//     },
//     {   title: '',
//         company:  '',
//         description: '',
//         dates: '',
//         type: ''
//     },
//     {   title: '',
//         company:  '',
//         description: '',
//         dates: '',
//         type: ''
//     },
//     {   title: '',
//         company:  '',
//         description: '',
//         dates: '',
//         type: ''
//     },
//     {   title: '',
//         company:  '',
//         description: '',
//         dates: '',
//         type: ''
//     },
//     {   title: '',
//         company:  '',
//         description: '',
//         dates: '',
//         type: ''
//     }
];

let slideIndex = 0; // Initialize slide index globally

export function changeSlides(n = 1) {
  let i;
  let slides = document.getElementsByClassName("slider-item");

  // Hide all slides initially
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }

  // Adjust the slideIndex based on n
  slideIndex += n;
  
  // Wrap the slide index around if it exceeds the number of slides
  if (slideIndex > slides.length) {
    slideIndex = 1;
  } else if (slideIndex < 1) {
    slideIndex = slides.length;
  }

  // Display the current slide
  slides[slideIndex - 1].style.display = "block";

  // Automatically move to the next slide after 10 seconds
  setTimeout(() => changeSlides(1), 10000); // Call the function again with a +1 increment
}


export {myExperience, slideIndex}