import React, { useEffect, useState } from 'react';
import personalPic from '../../resources/personalPic.jpg'
import { simulateTyping } from './About.js';
import './About.css';


const string1 = [
  'Constant Achiever',
  'Analytical Thinker',
  'Code Craftsman',
  'Control Logic Engineer',
  'Logic-Driven Innovator'
];

const string2 = [
  'Apple / MacOS',
  'Microsoft Office Suite',
  'Github',
  'RSLogix 5/500/5000',
  'PanelView',
  'PanelBuilder',
  'AutCAD',
  'Solidworks',
  'Fanuc',
  'Motoman'
];

const string3 = [
  'HTML/5',
  'CSS',
  'JavaScript',
  'Python',
  'MongoDB',
  'React',
  'NodeJS/Express',
  'Typescript',
  'MySQL',
  'Postgress',
  'VBA'
];


const About = ({ nView, oView, setOView }) => {
  const [hold, setHold] = useState(false);
  const [test, setTest] = useState(false);

  useEffect(() => {
    const stopTyping1 = simulateTyping(string1, 'typingArea1');
    const stopTyping2 = simulateTyping(string2, 'typingArea2');
    const stopTyping3 = simulateTyping(string3, 'typingArea3');

    return () => {
      stopTyping1(); stopTyping2(); stopTyping3(); // Ensure typing stops on unmount
    };
  }, []);



  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleDelay = async () => {
    await delay(1500);
    setOView('');
  };

  useEffect(() => {
    if (nView === 'about') {
      setHold(true);
      setTest(true);
    } else if (oView === 'about') {
      setTest(false);
      handleDelay();
    } else if (oView === '') {
      setHold(test);
    }
    
    // Cleanup for timers or intervals inside showSlides
    return () => {
      // Assuming showSlides might have a setInterval or setTimeout
      clearInterval(); // clear any active intervals if necessary
    };
  }, [nView, oView, test, setOView]);

  return (
    <div className={"page " + (hold ? (test ? 'fadeIn ' : 'fadeOut ') : 'hidden')}>
      <div className="Panel gridA">
        <img alt={personalPic} src={personalPic} className='pic'/>
        <p className="bio">Hello All,</p>
        <p className="indent bio b1">
        I'm Alex Carr, a Software Developer with a background in Controls 
        Engineering. My enthusiasm for science and technology has been a key 
        motivator throughout my career, enabling me to adapt and thrive in a 
        constantly changing field.</p>
        <p className="indent bio">
        The rapid pace of technological advancement presents both exciting 
        opportunities and challenges, and I firmly believe that information 
        technology holds the key to a more advanced and sustainable future. I'm 
        particularly passionate about the importance of transparency in data and 
        the creation of user-friendly systems, which are essential for empowering 
        individuals and businesses to make informed decisions.</p>
        <p className="indent bio">
        As I transition into software development, I focus on leveraging cutting-edge 
        technologies to help build that future. I’m driven by the potential to solve 
        complex problems and create innovative solutions that enhance how we interact 
        with and benefit from technology.
        </p>
        <div className='alt'>
          <div>I'm a</div>
          <div id='typingArea1'>1</div>
          <div>I have experience in</div>
          <div id='typingArea2'>2</div>
          <div>I have programmed in</div>
          <div id='typingArea3'>3</div>
        </div>
      </div>
    </div>
  );
};

export default About;
