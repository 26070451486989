
import React from 'react'
import './ContactBar.css'

function ContactBar() {
    return (
        <div></div>
    );
}

export default ContactBar