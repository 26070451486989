
import React, { useEffect, useState } from 'react';
import { myExperience, changeSlides, slideIndex } from './Experience.js';
import './Experience.css';

const Experience = ({ nView, oView, setOView }) => {
  const [hold, setHold] = useState(false);
  const [test, setTest] = useState(false);

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleDelay = async () => {
    await delay(1500);
    setOView('');
  };

  useEffect(() => {
    if (nView === 'experience') {
      setHold(true);
      setTest(true);
    } else if (oView === 'experience') {
      setTest(false);
      handleDelay();
    } else if (oView === '') {
      setHold(test);
    }

    // Cleanup for timers or intervals inside showSlides
    return () => {
      // Assuming showSlides might have a setInterval or setTimeout
      clearInterval(); // clear any active intervals if necessary
    };
  }, [nView, oView, test, setOView]);

  return (
    <div className={"page " + (hold ? (test ? 'fadeIn ' : 'fadeOut ') : 'hidden')}>
      <div className="Panel gridE">
        <div className='menu'>
          <h2>Education</h2>
          <h2>Certifications</h2>
          <h2>Technical Experience</h2>
          <h2>Professional Experience</h2>
        </div>
        <div className='slider'>
          {myExperience.map((item, index) => (
            <div key={index} className={`slider-item fadeInE ${index}`}>
              <h3>{item.title}</h3>
              <h4>{item.company}</h4>
            </div>
          ))}
          <div className='dotMenu'>
            {/* Ensure that the onClick passes a function and not a direct invocation */}
            <span className="dot" onClick={() => changeSlides(-2)}></span>
            <span className="dot" onClick={() => changeSlides(-1)}></span>
            <span className="dot"></span>
            <span className="dot" onClick={() => changeSlides(1)}></span>
            <span className="dot" onClick={() => changeSlides(2)}></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;

